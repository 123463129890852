var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.localize)?[_c('b-tabs',{staticClass:"base-input-tabs",model:{value:(_vm.languageTabIndex),callback:function ($$v) {_vm.languageTabIndex=$$v},expression:"languageTabIndex"}},_vm._l((_vm.languages),function(item,index){return _c('b-tab',{key:index,attrs:{"title":item}})}),1),_c('b-form-group',{attrs:{"label":_vm.label,"label-for":_vm.id || _vm.uid}},_vm._l((_vm.languages),function(item,index){return _c('validation-provider',{key:index,attrs:{"name":_vm.label + ' [' + item.toUpperCase() + ']',"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var dirty = ref.dirty;
var validated = ref.validated;
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"show",rawName:"v-show",value:(item === _vm.languages[_vm.languageTabIndex]),expression:"item === languages[languageTabIndex]"}],attrs:{"id":_vm.id || _vm.uid,"value":_vm.value[item],"placeholder":_vm.placeholder,"state":dirty || validated ? valid : null,"disabled":_vm.disabled},on:{"input":function($event){return _vm.handleInput($event, item)}}}),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})}),1)]:[_c('validation-provider',{attrs:{"name":_vm.label,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var dirty = ref.dirty;
var validated = ref.validated;
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.label,"label-for":_vm.id || _vm.uid}},[_c('b-form-input',{attrs:{"id":_vm.id || _vm.uid,"value":_vm.value,"placeholder":_vm.placeholder,"state":dirty || validated ? valid : null,"disabled":_vm.disabled},on:{"input":function($event){return _vm.$emit('input', $event)}}}),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }