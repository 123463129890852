<template>
  <div>
    <template v-if="localize">
      <b-tabs
        v-model="languageTabIndex"
        class="base-input-tabs"
      >

        <b-tab
          v-for="(item, index) in languages"
          :key="index"
          :title="item"
        />
      </b-tabs>
      <b-form-group
        :label="label"
        :label-for="id || uid"
      >
        <validation-provider
          v-for="(item, index) in languages"
          :key="index"
          v-slot="{ dirty, validated, valid, errors }"
          :name="label + ' [' + item.toUpperCase() + ']'"
          :rules="rules"
        >
          <b-form-input
            v-show="item === languages[languageTabIndex]"
            :id="id || uid"
            :value="value[item]"
            :placeholder="placeholder"
            :state="dirty || validated ? valid : null"
            :disabled="disabled"
            @input="handleInput($event, item)"
          />
          <small class="text-danger d-block">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </template>
    <template v-else>
      <validation-provider
        v-slot="{ dirty, validated, valid, errors }"
        :name="label"
        :rules="rules"
      >
        <b-form-group
          :label="label"
          :label-for="id || uid"
        >
          <b-form-input
            :id="id || uid"
            :value="value"
            :placeholder="placeholder"
            :state="dirty || validated ? valid : null"
            :disabled="disabled"
            @input="$emit('input', $event)"
          />
          <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BaseInputText',
  props: {
    id: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number, Object],
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    localize: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      languageTabIndex: this.indexCurrentLanguage,
    }
  },
  computed: {
    ...mapGetters({
      languages: 'languages/getLanguages',
      currentLanguage: 'languages/getCurrentLanguage',
    }),
    uid() {
      // eslint-disable-next-line no-underscore-dangle
      return this._uid.toString()
    },
    indexCurrentLanguage() {
      return this.languages.findIndex(language => language === this.currentLanguage)
    },
  },
  methods: {
    handleInput(text, language) {
      const payload = this.localize ? { ...this.value, [language]: text } : text
      this.$emit('input', payload)
    },
  },
}
</script>

<style scoped>
.base-input-tabs >>> .nav-tabs { margin-bottom: 4px }
</style>
